import React from "react";
import Header from "../components/header";

import {Helmet} from "react-helmet";
import "../styles/index.css";
import {StaticImage} from "gatsby-plugin-image";

function Index() {
    return (
        <main>
            <Helmet>
                <title>IxDF Bootcamps Site</title>
            </Helmet>

            <Header/>

            <section className="bg-ixdf">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1">
                            <h1 class="text-light">IxDF Bootcamps Site</h1>


                            <p class="lead text-light">
                                Designed and shipped landing/signup pages for IxDF Bootcamps that attracted <strong>1200
                                students x $2,400=$2.8m gross revenue in the first 6 months.</strong></p>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2">
                            <StaticImage
                                src="../images/card-thumb-bootcamps-site.png"
                                alt="Bootcamps site on a macbook"
                                placeholder="blurred"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- content --> */}

            <div className="message">Case study snapshot. Full version available by request. </div>


            <section>
                <div class="container">
                    <div class="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <h2>Situation</h2>
                            <p className="">
                                IxDF launched an ambitious new Bootcamp program, and needed landing pages that worked
                                with advertising creative to increase conversions.</p>

                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <h2>Task</h2>
                            <p className="">
                                Design and ship landing pages for all Bootcamps to maximize conversions and minimize
                                onboarding efforts.

                            </p>

                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <h2>Team/Stakeholders</h2>

                            <ul className="">
                                <li>
                                    Sunandita, Director of Growth
                                </li>
                                <li>
                                    Nadya, Director of Bootcamps
                                </li>
                                <li>
                                    Alies, Back End Developer
                                </li>
                                <li>
                                    Hasan, Front End Developer
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <h2>Actions</h2>

                            <ul className="">
                                <li>
                                    Research—Surveys of 2,000 members, Interviews with 20 members.
                                </li>
                                <li>
                                    Definition—Competitive Analysis, Wireframes, User Testing
                                </li>
                                <li>
                                    Design—high fidelity screens, 3x iteration.
                                </li>
                                <li>
                                    Documentation—Design decisions—especially pricing, image choices, schedules, copy.
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <h2>Result</h2>
                            <p className="">
                                The new Bootcamp program brought in <strong>1200 students x $2,400=$2.8m gross revenue in the first 6 months.</strong>
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- end content --> */}
        </main>
    );
}

export default Index;
